import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import googlesvg from "../../Assests/google-icon.svg";

const Login = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const res = await axios.post(`${server}/user/login-user`, {
				email,
				password,
			});
			localStorage.setItem("token", JSON.stringify(res.data.token));
			localStorage.setItem("user", JSON.stringify(res.data.user));
			toast.success("Login Successful!");
			navigate("/");
			window.location.reload(true);
		} catch (err) {
			console.log(err);
			toast.error(err.response?.data.message);
		} finally {
			setLoading(false);
		}
	};

	const handleGoogleLogin = async () => {
		try {
			const { data } = await axios.get(`${server}/google/initiate`);

			const url = data.data.url;

			window.location.href = url; // Redirect to Google OAuth API endpoint
		} catch (error) {
			toast.error("Google login failed.");
		}
	};

	return (
		<div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			{loading && (
				<div className="fixed inset-0 flex items-center justify-center bg-gray-50 bg-opacity-75 z-50">
					<div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-300"></div>
				</div>
			)}
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Buyer login</h2>
			</div>
			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
					<form className="space-y-6" onSubmit={handleSubmit}>
						<div>
							<label
								htmlFor="email"
								className="block text-sm font-medium text-gray-700"
							>
								Email address
							</label>
							<div className="mt-1">
								<input
									type="email"
									name="email"
									autoComplete="email"
									required
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								/>
							</div>
						</div>
						<div>
							<label
								htmlFor="password"
								className="block text-sm font-medium text-gray-700"
							>
								Password
							</label>
							<div className="mt-1 relative">
								<input
									type={visible ? "text" : "password"}
									name="password"
									autoComplete="current-password"
									required
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								/>
								{visible ? (
									<AiOutlineEye
										className="absolute right-2 top-2 cursor-pointer"
										size={25}
										onClick={() => setVisible(false)}
									/>
								) : (
									<AiOutlineEyeInvisible
										className="absolute right-2 top-2 cursor-pointer"
										size={25}
										onClick={() => setVisible(true)}
									/>
								)}
							</div>
						</div>
						<div className={`${styles.noramlFlex} justify-between`}>
							<div className={`${styles.noramlFlex}`}>
								<input
									type="checkbox"
									name="remember-me"
									id="remember-me"
									className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
								/>
								<label
									htmlFor="remember-me"
									className="ml-2 block text-sm text-gray-900"
								>
									Remember me
								</label>
							</div>
							<div className="text-sm">
								<a
									href="forgot-password"
									className="font-medium text-blue-600 hover:text-blue-500"
								>
									Forgot your password?
								</a>
							</div>
						</div>
						<div>
							<button
								type="submit"
								className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
								disabled={loading}
							>
								Submit
							</button>
						</div>
						<div className="mt-4 flex justify-center">
							<button
								type="button"
								onClick={handleGoogleLogin}
								className="w-full h-[40px] flex items-center justify-center border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-900 bg-white hover:bg-gray-100"
							>
								<img
									src={googlesvg}
									alt="Google"
									className="w-5 h-5 mr-2"
								/>
								Continue with Google
							</button>
						</div>
						<div className={`${styles.noramlFlex} w-full`}>
							<h4>No buyer account?</h4>
							<Link to="/sign-up" className="text-blue-600 pl-2">
								Sign Up
							</Link>
						</div>
						<div className={`${styles.noramlFlex} w-full`}>
							<p>
								Become a vendor.
								<Link to="/shop-create" className="text-blue-600 pl-2">
									{" "}
									Sign Up{" "}
								</Link>
								{" or"}
								<Link to="/shop-login" className="text-blue-600 pl-2">
									{" "}
									login{" "}
								</Link>
							</p>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Login;
