import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
// import { RxAvatar } from "react-icons/rx";
import { useEffect } from "react";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import googlesvg from "../../Assests/google-icon.svg";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  // const [avatar, setAvatar] = useState(null);
  const [university, setUniversity] = useState("");
	const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(false);

  // const handleFileInputChange = (e) => {
  //   const reader = new FileReader();

  //   reader.onload = () => {
  //     if (reader.readyState === 2) {
  //       setAvatar(reader.result);
  //     }
  //   };

  //   reader.readAsDataURL(e.target.files[0]);
  // };

  useEffect(() => {
		const fetchUniversities = async () => {
			try {
				const response = await axios.get(`${server}/university/all`);
				setUniversities(response.data.universities);
			} catch (error) {
				console.error("Error fetching universities", error);
				toast.error("Error fetching universities");
			}
		};
	
		fetchUniversities();
	}, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await axios.post(`${server}/user/create-user`, {
        name,
        email,
        password,
        // avatar,
        university,
      });
      toast.success(res.data.message);
      setName("");
      setEmail("");
      setPassword("");
      // setAvatar(null);
      setUniversity("");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
		try {
			const { data } = await axios.get(`${server}/google/initiate`);

			const url = data.data.url;

			window.location.href = url; // Redirect to Google OAuth API endpoint
		} catch (error) {
			toast.error("Google login failed.");
		}
  };

  return (
		<div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
			{loading && (
				<div className="fixed inset-0 flex items-center justify-center bg-gray-50 bg-opacity-75 z-50">
					<div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-300"></div>
				</div>
			)}
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
					Register as a new user
				</h2>
			</div>
			<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
					<form className="space-y-6" onSubmit={handleSubmit}>
						<div>
							<label
								htmlFor="name"
								className="block text-sm font-medium text-gray-700"
							>
								Full Name
							</label>
							<div className="mt-1">
								<input
									type="text"
									name="name"
									autoComplete="name"
									required
									value={name}
									onChange={(e) => setName(e.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								/>
							</div>
						</div>

						<div>
							<label
								htmlFor="email"
								className="block text-sm font-medium text-gray-700"
							>
								Email address
							</label>
							<div className="mt-1">
								<input
									type="email"
									name="email"
									autoComplete="email"
									required
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								/>
							</div>
						</div>
						<div className="mt-1 relative">
							<label
								htmlFor="dropdown"
								className="block text-sm font-medium text-gray-700"
							>
								University
							</label>
							<select
								name="university"
								value={university}
								onChange={(e) => setUniversity(e.target.value)}
								required
								className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
							>
								<option value="" disabled hidden>
									Select university
								</option>
								{universities &&
									universities.map((university) => (
										<option
											key={university.id}
											value={university.name}
										>
											{university.name}
										</option>
									))}
							</select>
						</div>
						<div>
							<label
								htmlFor="password"
								className="block text-sm font-medium text-gray-700"
							>
								Password
							</label>
							<div className="mt-1 relative">
								<input
									type={visible ? "text" : "password"}
									name="password"
									autoComplete="current-password"
									required
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								/>
								{visible ? (
									<AiOutlineEye
										className="absolute right-2 top-2 cursor-pointer"
										size={25}
										onClick={() => setVisible(false)}
									/>
								) : (
									<AiOutlineEyeInvisible
										className="absolute right-2 top-2 cursor-pointer"
										size={25}
										onClick={() => setVisible(true)}
									/>
								)}
							</div>
						</div>
						{/* <div>
              <label
                htmlFor="avatar"
                className="block text-sm font-medium text-gray-700"
              ></label>
              <div className="mt-2 flex items-center">
                <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
                  {avatar ? (
                    <img
                      src={avatar}
                      alt="avatar"
                      className="h-full w-full object-cover rounded-full"
                    />
                  ) : (
                    <RxAvatar className="h-8 w-8" />
                  )}
                </span>
                <label
                  htmlFor="file-input"
                  className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <span>Upload a file</span>
                  <input
                    type="file"
                    name="avatar"
                    id="file-input"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleFileInputChange}
                    className="sr-only"
                  />
                </label>
              </div>
            </div> */}
						<div>
							<button
								type="submit"
								className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
								disabled={loading}
							>
								Submit
							</button>
						</div>
						<div className="mt-4 flex justify-center">
							<button
								type="button"
								onClick={handleGoogleLogin}
								className="w-full h-[40px] flex items-center justify-center border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-900 bg-white hover:bg-gray-100"
							>
								<img
									src={googlesvg}
									alt="Google"
									className="w-5 h-5 mr-2"
								/>
								Continue with Google
							</button>
						</div>
						<div className={`${styles.noramlFlex} w-full`}>
							<h4>Already have an account?</h4>
							<Link to="/login" className="text-blue-600 pl-2">
								Sign In
							</Link>
						</div>
					</form>
				</div>
			</div>
		</div>
  );
};

export default Signup;
