import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { toast } from "react-toastify";
// import TicketDetails from "../components/Events/Ticket/TicketDetails";
import EventDetails from "../components/Events/EventDetails";
import axios from "axios";
import Header from "../components/Layout/Header";
import { server } from "../server";

const EventDetailsPage = () => {
	const { customName } = useParams();
	const dispatch = useDispatch();
	const [event, setEvent] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${server}/event/get-event-by-custom-name/${customName}`
				);
				setEvent(response.data.event);
			} catch (error) {
				console.error("Error fetching event (unexpected):", error);
				setEvent(null);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [customName]);

	useEffect(() => {
		if (!loading && !event) {
			window.location.href = "/404";
		}
	}, [event, loading]);
	
	return (
		<>
			<Header />
			<div>
				{loading && (
					<div className="fixed inset-0 flex items-center justify-center bg-gray-50 bg-opacity-75 z-50">
						<div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-300"></div>
					</div>
				)}
				{!loading && event && <EventDetails event={event} />}
			</div>
		</>
	);
};

export default EventDetailsPage;
