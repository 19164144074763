import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { server } from "../server";

const GoogleAuthPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const code = params.get("code");

		if (code) {
			axios.post(`${server}/google/callback`, { code })
				.then((res) => {
					localStorage.setItem("token", JSON.stringify(res.data.data.token));
					localStorage.setItem("user", JSON.stringify(res.data.data.user));
                    setLoading(false);
					navigate("/");
					window.location.reload(true);
				})
				.catch((error) => {
					console.error("Error during authentication", error);
				});
		}
	}, [location, navigate]);

	return (
		<div>
			{loading && (
				<div className="fixed inset-0 flex items-center justify-center bg-gray-50 bg-opacity-75 z-50">
					<div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-300"></div>
				</div>
			)}
		</div>
	);
};

export default GoogleAuthPage;
