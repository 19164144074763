import React, { useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Events from "../components/Events/Events";
// import Sponsored from "../components/Route/Sponsored";
import Footer from "../components/Layout/Footer";

const HomePage = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false); // Set loading to false after a short delay
		}, 500);

		return () => clearTimeout(timer);
	}, []);

	return (
		<div>
			{loading && (
				<div className="fixed inset-0 flex items-center justify-center bg-gray-50 bg-opacity-75 z-50">
					<div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-300"></div>
				</div>
			)}
			<Header activeHeading={1} />
			<Hero />
			<Categories />
			<BestDeals />
			<Events />
			<FeaturedProduct />
			{/* <Sponsored /> */}
			<Footer />
		</div>
	);
};

export default HomePage;
