import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteEvent, getAllEventsShop } from "../../redux/actions/event";
import { getAllProductsShop } from "../../redux/actions/product";
import { deleteProduct } from "../../redux/actions/product";
import Loader from "../Layout/Loader";
import { server } from "../../server";
import { toast } from "react-toastify";

const AllEvents = () => {
	const { events, isLoading } = useSelector((state) => state.events);
	const { seller } = useSelector((state) => state.seller);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAllEventsShop(seller._id));
	}, [dispatch]);

	const handleDelete = (id) => {
		dispatch(deleteEvent(id));
		window.location.reload();
	};

	const handleStatusChange = async (event) => {
		// const selectedStatus = event.target.value;

		const id = event.target.dataset.id;
		const token = JSON.parse(localStorage.getItem("token"));

		try {
			// Call your API here
			const response = await fetch(`${server}/event/update-status/${id}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				// body: {},
			});

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const result = await response.json();
			toast.success(result.message);
			window.location.reload();
		} catch (error) {
			console.error("Error calling API:", error);
			toast.error("Failed to update status");
		}
	};

	const columns = [
		{ field: "id", headerName: "Product Id", minWidth: 150, flex: 0.7 },
		{
			field: "name",
			headerName: "Name",
			minWidth: 180,
			flex: 1.4,
		},
		{
			field: "price",
			headerName: "Price",
			minWidth: 100,
			flex: 0.6,
		},
		{
			field: "Stock",
			headerName: "Stock",
			type: "number",
			minWidth: 80,
			flex: 0.5,
		},
		{
			field: "sold",
			headerName: "Sold out",
			type: "number",
			minWidth: 130,
			flex: 0.6,
		},
		{
			field: "Preview",
			flex: 0.8,
			minWidth: 100,
			headerName: "",
			type: "number",
			sortable: false,
			renderCell: (params) => {
				const d = params.row.name;
				const product_name = d.toLowerCase().replace(/\s+/g, "-");
				return (
					<>
						<Link to={`/events/${product_name}`}>
							<Button>
								<AiOutlineEye size={20} />
							</Button>
						</Link>
					</>
				);
			},
		},
		{
			field: " ",
			headerName: "Status",
			type: "number",
			minWidth: 130,
			flex: 0.6,
			renderCell: (params) => {
				return (
					console.log(params.row),
					(
						<>
							{/* <Button> */}
							<select
								name=""
								id=""
								value={params.row.status}
								data-id={params.row.id}
								onChange={handleStatusChange}
							>
								<option value="Running">Active</option>
								<option value="Closed">Deactivate</option>
							</select>
							{/* </Button> */}
						</>
					)
				);
			},
		},
		{
			field: "Delete",
			flex: 0.8,
			minWidth: 120,
			headerName: "",
			type: "number",
			sortable: false,
			renderCell: (params) => {
				return (
					<>
						<Button onClick={() => handleDelete(params.id)}>
							<AiOutlineDelete size={20} />
						</Button>
					</>
				);
			},
		},
	];

	const row = [];

	events &&
		events.forEach((item) => {
			row.push({
				id: item._id,
				name: item.name,
				price: "₦" + item.discountPrice,
				Stock: item.stock,
				sold: item.sold_out,
				status: item.status,
			});
		});

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<div className="w-full mx-8 pt-1 mt-10 bg-white">
					<DataGrid
						rows={row}
						columns={columns}
						pageSize={10}
						disableSelectionOnClick
						autoHeight
					/>
				</div>
			)}
		</>
	);
};

export default AllEvents;
