import React, { useEffect } from 'react';
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Lottie from "react-lottie";
import animationData from "../Assests/animations/107043-success.json";

const OrderSuccessPage = () => {
  useEffect(() => {
    localStorage.removeItem("ticket_name");

    localStorage.setItem("cartItems", JSON.stringify([]));

  }, []);

  return (
    <div>
      <Header />
      <Success />
      <Footer />
    </div>
  );
};

const Success = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <Lottie options={defaultOptions} width={300} height={300} />
      <h5 className="text-center mb-14 text-[25px] text-[#000000a1]">
        Your order is successful 😍
      </h5>
      <h6 className="text-center mb-14 text-[20px] text-[#000000a1]">
        Check your mail inbox or spam!
      </h6>
      <br />
      <br />
    </div>
  );
};

export default OrderSuccessPage;
