import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../components/Layout/Header';

function GoogleFormEmbed() {
	const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    let load = 0;

    const formUrlX =
		"https://docs.google.com/forms/d/e/1FAIpQLScYv6YI_e8H8vMqf8OvrwsGDd3ek-la-c__NKgBh6OHyLvI2A/viewform?embedded=true";

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {

        document.getElementById('gform').onload = function () {

            /* Execute on every reload on iFrame */
            load++;
            if (load > 1) {
                navigate("/payment")
            }
        }

    })

    return (
        <>
            <Header />
            {loading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-50 bg-opacity-75 z-50">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-300"></div>
                </div>
            )}
            <iframe
                src={formUrlX}
                title="Google Form"
                id='gform'
                className="w-full h-[100vh] md:h-[100vh] lg:h-[100vh] border-0"
                allowfullscreen
            />
        </>
    );
}

export default GoogleFormEmbed;
