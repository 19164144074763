import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../server";
import styles from "../../styles/styles";

const Payment = () => {
  const [orderData, setOrderData] = useState([]);
  const [cashOnDelivery, setCashOnDelivery] = useState(false);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    const latestOrder = JSON.parse(localStorage.getItem("latestOrder"));
    setOrderData(latestOrder);
  }, []);

  useEffect(() => {
    if (orderData && orderData.cart) {
      const allShopsHaveDelivery = orderData.cart.every(product => product?.shop?.hasDelivery);
      setCashOnDelivery(allShopsHaveDelivery);
    }
  }, [orderData.cart]);

  const paymentHandler = async () => {
    try {
      const coupon = orderData.couponCode;

      if (coupon && orderData?.totalPrice == 0) {
        
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };

        const order = {
          cart: orderData?.cart,
          shippingAddress: orderData?.shippingAddress,
          user: user && user,
          totalPrice: orderData?.totalPrice,
          phoneNumber: orderData?.phoneNumber,
          coupon,
          paymentInfo: {
            type: "100% coupon",
          },
          orderType: orderData?.orderType
        };

        try {
          
          await axios.post(`${server}/order/create-order`, order, config);
          navigate("/order/success");
          toast.success("Order successful!");
          localStorage.setItem("cartItems", JSON.stringify([]));
          localStorage.setItem("latestOrder", JSON.stringify([]));
          window.location.reload();
        } catch (error) {
          toast.error("Failed to create order");
        }

        return;
      } 

      const response = await axios.post(`${server}/payment/process`, {
        email: orderData?.user.email,
        // amount: parseInt(orderData?.totalPrice, 10).toFixed(0) * 100,
        // splitData,
        order: orderData,
      });

      const paymentRef = response?.data?.data?.reference;

      const updatedOrderData = { ...orderData, paymentRef };
      setOrderData(updatedOrderData);
      localStorage.setItem("latestOrder", JSON.stringify(updatedOrderData));

      const authorizationUrl = response?.data?.data?.authorization_url;

      window.location.href = authorizationUrl;

      // navigate('/order/processing', { state: { authorizationUrl } });
    } catch (error) {
      toast.error("Failed to initiate new payment method");
    }
  };

  const cashOnDeliveryHandler = async (e) => {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const order = {
      cart: orderData?.cart,
      shippingAddress: orderData?.shippingAddress,
      user: user && user,
      totalPrice: orderData?.totalPrice,
      phoneNumber: orderData?.phoneNumber,
      coupon: orderData?.couponCode,
      paymentInfo: {
        type: "Pay On Delivery",
      },
      orderType: orderData?.orderType
    };

    try {
      await axios.post(`${server}/order/create-order`, order, config);
      navigate("/order/success");
      toast.success("Order successful!");
      localStorage.setItem("cartItems", JSON.stringify([]));
      localStorage.setItem("latestOrder", JSON.stringify([]));
      window.location.reload();
    } catch (error) {
      toast.error("Failed to create order");
    }
  };

  return (
    <div className="w-full flex flex-col items-center py-8">
      <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
        <div className="w-full 800px:w-[65%]">
          <PaymentInfo
            paymentHandler={paymentHandler}
            cashOnDeliveryHandler={cashOnDeliveryHandler}
            cashOnDelivery={cashOnDelivery}
          />
        </div>
        <div className="w-full 800px:w-[35%] 800px:mt-0 mt-8">
          <CartData orderData={orderData} />
        </div>
      </div>
    </div>
  );
};


const PaymentInfo = ({ paymentHandler, cashOnDeliveryHandler, cashOnDelivery }) => {
  const [select, setSelect] = useState(1);

  return (
    <div className="w-full 800px:w-[95%] bg-[#fff] rounded-md p-5 pb-8">
      <br />
      {/* paystack payment */}
      <div>
        <div className="flex w-full pb-5 border-b mb-2">
          <div
            className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center"
            onClick={() => setSelect(1)}
          >
            {select === 1 ? (
              <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
            ) : null}
          </div>
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            Pay with different methods
          </h4>
        </div>

        {select === 1 ? (
          <div className="w-full flex border-b">
            <div
              className={`${styles.button} !bg-[#f63b60] text-white h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
              onClick={paymentHandler}
            >
              Pay Now
            </div>
          </div>
        ) : null}

        {/* cash on delivery */}
        {cashOnDelivery && (
          <div>
            <div className="flex w-full pb-5 border-b mb-2">
              <div
                className="w-[25px] h-[25px] rounded-full bg-transparent border-[3px] border-[#1d1a1ab4] relative flex items-center justify-center"
                onClick={() => setSelect(2)}
              >
                {select === 2 ? (
                  <div className="w-[13px] h-[13px] bg-[#1d1a1acb] rounded-full" />
                ) : null}
              </div>
              <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
                Pay on Delivery
              </h4>
            </div>

            {select === 2 ? (
              <div className="w-full flex">
                <form className="w-full" onSubmit={cashOnDeliveryHandler}>
                  <input
                    type="submit"
                    value="Confirm"
                    className={`${styles.button} !bg-[#f63b60] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
                  />
                </form>
              </div>
            ) : null}
          </div>
        )}
      </div>
      <br />
    </div>
  );
};

const CartData = ({ orderData }) => {
  // const shipping = orderData?.shipping?.toFixed(2);
  return (
    <div className="w-full bg-[#fff] rounded-md p-5 pb-8">
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">subtotal:</h3>
        <h5 className="text-[18px] font-[600]">₦{orderData?.subTotalPrice}</h5>
      </div>
      {/* <br />
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">shipping:</h3>
        <h5 className="text-[18px] font-[600]">₦{shipping}</h5>
      </div> */}
      <br />
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">
          Payment processing fee:
        </h3>
        <h5 className="text-[18px] font-[600]">₦{orderData?.gatewayFee}</h5>
      </div>
      <br />
      <div className="flex justify-between border-b pb-3">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">Discount:</h3>
        <h5 className="text-[18px] font-[600]">
          {orderData?.discountPrice ? "₦" + orderData.discountPrice : "-"}
        </h5>
      </div>
      <h5 className="text-[18px] font-[600] text-end pt-3">
        ₦{orderData?.totalPrice}
      </h5>
      <br />
    </div>
  );
};

export default Payment;