import React, { useState, useEffect } from "react";
import styles from "../../styles/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

const Checkout = () => {
	const { user } = useSelector((state) => state.user);
	const { cart } = useSelector((state) => state.cart);
	const [city, setCity] = useState("");
	const [universities, setUniversities] = useState([]);
	const [selectedUniversity, setSelectedUniversity] = useState("");
	const [userInfo, setUserInfo] = useState(false);
	const [address1, setAddress1] = useState("");
	const [couponCode, setCouponCode] = useState("");
	const [couponCodeData, setCouponCodeData] = useState(null);
	const [phoneNumber, setPhoneNumber] = useState(null);
	const [gatewayFee, setGatewayFee] = useState(0);
	const [discountPrice, setDiscountPrice] = useState(null);
	const [discountedSubTotalPrice, setDiscountedSubTotalPrice] = useState(null);

	const navigate = useNavigate();
	const location = useLocation();
	const token = JSON.parse(localStorage.getItem("token"));

	/*
	const eventName = JSON.parse(localStorage.getItem("ticket_name"));
	const mssnTicket = eventName === "Ramadan Iftar & Sahur";
	*/

	// Parse query parameters
	const searchParams = new URLSearchParams(location.search);
	const isTicket = searchParams.get("isTicket") === "true";

	const subTotalPrice = cart.reduce((acc, item) => acc + item.qty * item.discountPrice, 0);

	const calculateGatewayFee = async (amount) => {
		try {
			const response = await axios.post(`${server}/payment/calculate-fee`, { amount });
			setGatewayFee(response.data.fee);
		} catch (error) {
			console.error("Error calculating fee", error);
			toast.error("Error calculating payment gateway fee");
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (subTotalPrice > 0) {
			const hasCharges = cart.filter((item) => item.hasOwnProperty("charges") && item.charges);

			const itemsWithChargesTotal = hasCharges.reduce(
				(acc, item) => acc + item.qty * item.discountPrice,
				0
			);

			const amount = itemsWithChargesTotal
				? Number(subTotalPrice - itemsWithChargesTotal)
				: subTotalPrice;

			calculateGatewayFee(amount);
		}
	}, [subTotalPrice, cart]);

	useEffect(() => {
		const fetchUniversities = async () => {
			try {
				const response = await axios.get(`${server}/university/all`);
				setUniversities(response.data.universities);
			} catch (error) {
				console.error("Error fetching universities", error);
				toast.error("Error fetching universities");
			}
		};

		fetchUniversities();
	}, []);

	const paymentSubmit = () => {
		const regex = /^0\d{10}$/;

		const isPhoneNumberValid = regex.test(phoneNumber);

		if (!isTicket && (address1 === "" || selectedUniversity === "")) {
			toast.error("Please choose your delivery address!");
		} else if (!isPhoneNumberValid) {
			toast.error("Please enter a valid phone number!");
		} else {
			const shippingAddress = {
				address1,
				city: selectedUniversity,
			};

			const orderData = {
				cart,
				totalPrice,
				subTotalPrice,
				gatewayFee,
				discountPrice,
				shippingAddress,
				user,
				phoneNumber,
				paymentRef: "",
				couponCode: couponCodeData?.name,
				orderType: isTicket ? "Tickets" : "Others",
			};

			localStorage.setItem("latestOrder", JSON.stringify(orderData));

			// Add form link here
			// navigate(mssnTicket ? "/events/mssnlasuepe/form" : "/payment");
			navigate("/payment");
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const name = couponCode;

		try {
			const res = await axios.get(`${server}/coupon/get-coupon-value/${name}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});
			const couponCodeData = res.data.couponCode;

			if (res.status !== 200) {
				toast.error(res.data?.message);
			}

			if (couponCodeData) {
				const shopId = couponCodeData.shopId;
				const couponCodeValue = couponCodeData.value;
				const maxUsagePerUser = couponCodeData.maxUsagePerUser;

				const isCouponValid = cart.filter((item) => item.shopId === shopId);

				if (isCouponValid.length === 0) {
					toast.error("Coupon code is not valid for this shop");
					setCouponCode("");
				} else {
					let eligiblePrice;
					if (maxUsagePerUser === 1) {
						eligiblePrice = isCouponValid[0].discountPrice;
					} else {
						eligiblePrice = isCouponValid.reduce(
							(acc, item) => acc + item.qty * item.discountPrice,
							0
						);
					}

					const discountPrice = (eligiblePrice * couponCodeValue) / 100;
					const discountedSubTotalPrice = subTotalPrice - discountPrice;

					calculateGatewayFee(discountedSubTotalPrice);
					setDiscountPrice(discountPrice);
					setCouponCodeData(couponCodeData);
					setCouponCode("");
					setDiscountedSubTotalPrice(discountedSubTotalPrice);
				}
			} else {
				toast.error("Coupon code doesn't exist!");
				setCouponCode("");
			}
		} catch (error) {
			console.error("Error fetching coupon", error);
			toast.error(error.response.data?.message);
		}
	};

	const discountPercentage = couponCodeData ? discountPrice : "";

	const totalPrice = couponCodeData
		? (discountedSubTotalPrice + (gatewayFee || 0)).toFixed(2)
		: (subTotalPrice + (gatewayFee || 0)).toFixed(2);

	return (
		<div className="w-full flex flex-col items-center py-8">
			<div className="w-[90%] 1000px:w-[70%] block 800px:flex">
				<div className="w-full 800px:w-[65%]">
					<ShippingInfo
						user={user}
						city={city}
						setCity={setCity}
						userInfo={userInfo}
						setUserInfo={setUserInfo}
						address1={address1}
						setAddress1={setAddress1}
						phoneNumber={phoneNumber}
						setPhoneNumber={setPhoneNumber}
						selectedUniversity={selectedUniversity}
						setSelectedUniversity={setSelectedUniversity}
						universities={universities}
						isTicket={isTicket}
					/>
				</div>
				<div className="w-full 800px:w-[35%] 800px:mt-0 mt-8">
					<CartData
						handleSubmit={handleSubmit}
						totalPrice={totalPrice}
						subTotalPrice={subTotalPrice}
						couponCode={couponCode}
						setCouponCode={setCouponCode}
						discountPercentage={discountPercentage}
						gatewayFee={gatewayFee}
					/>
				</div>
			</div>
			<div className={`${styles.button} w-[150px] 800px:w-[280px] mt-10`} onClick={paymentSubmit}>
				{/* <h5 className="text-white">{mssnTicket ? "Next" : "Go to Payment"}</h5> */}
				<h5 className="text-white">{"Go to Payment"}</h5>
			</div>
		</div>
	);
};

const ShippingInfo = ({
	user,
	setCity,
	userInfo,
	setUserInfo,
	address1,
	setAddress1,
	phoneNumber,
	setPhoneNumber,
	selectedUniversity,
	setSelectedUniversity,
	universities,
	isTicket,
}) => {
	return (
		<div className="w-full 800px:w-[95%] bg-white rounded-md p-5 pb-8">
			<h5 className="text-[18px] font-[500]">Shipping Address</h5>
			<br />
			<form>
				<div className="w-full flex pb-3">
					<div className="w-[50%]">
						<label className="block pb-2">Full Name</label>
						<input
							type="text"
							value={user && user.name}
							required
							className={`${styles.input} !w-[95%]`}
						/>
					</div>
					<div className="w-[50%]">
						<label className="block pb-2">Email Address</label>
						<input
							type="email"
							value={user && user.email}
							required
							className={`${styles.input}`}
						/>
					</div>
				</div>

				<div className="w-full flex pb-3">
					<div className={isTicket ? "w-[100%]" : "w-[50%]"}>
						<label className="block pb-2">Phone Number</label>
						<input
							type="number"
							required
							value={phoneNumber}
							placeholder="e.g 08123456789"
							onChange={(e) => setPhoneNumber(e.target.value)}
							className={`${styles.input} !w-[95%]`}
						/>
					</div>
					{!isTicket && (
						<div className="w-[50%]">
							<label className="block pb-2">University</label>
							<select
								className="w-[95%] border h-[40px] rounded-[5px]"
								value={selectedUniversity}
								onChange={(e) => setSelectedUniversity(e.target.value)}
							>
								<option className="block pb-2" value="">
									Choose your University
								</option>
								{universities &&
									universities.map((university) => (
										<option
											key={university.id}
											value={university.name}
										>
											{university.name}
										</option>
									))}
							</select>
						</div>
					)}
				</div>

				<div className="w-full flex pb-3">
					<div className="w-[100%]">
						<label className="block pb-2">
							{isTicket ? "Where are you coming from" : "Address"}
						</label>
						<input
							type="address"
							required
							value={address1}
							onChange={(e) => setAddress1(e.target.value)}
							className={`${styles.input} !w-[95%]`}
							placeholder={isTicket ? "" : "Beside lecture theatre..."}
						/>
					</div>
				</div>
			</form>
			<h5 className="text-[18px] cursor-pointer inline-block" onClick={() => setUserInfo(!userInfo)}>
				Choose From saved address
			</h5>
			{userInfo && (
				<div>
					{user &&
						user.addresses.map((item, index) => (
							<div className="w-full flex mt-1" key={index}>
								<input
									type="checkbox"
									className="mr-3"
									value={item.addressType}
									onClick={() => setAddress1(item.address1)}
								/>
								<h2>{item.addressType}</h2>
							</div>
						))}
				</div>
			)}
		</div>
	);
};

const CartData = ({
	handleSubmit,
	totalPrice,
	subTotalPrice,
	couponCode,
	setCouponCode,
	discountPercentage,
	gatewayFee,
}) => {
	return (
		<div className="w-full bg-[#fff] rounded-md p-5 pb-8">
			<div className="flex justify-between">
				<h3 className="text-[16px] font-[400] text-[#000000a4]">subtotal:</h3>
				<h5 className="text-[18px] font-[600]">₦{subTotalPrice}</h5>
			</div>
			<br />
			{gatewayFee && (
				<div className="flex justify-between">
					<h3 className="text-[16px] font-[400] text-[#000000a4]">
						Payment processing fee:
					</h3>
					<h5 className="text-[18px] font-[600]">₦{gatewayFee}</h5>
				</div>
			)}
			<br />
			<div className="flex justify-between border-b pb-3">
				<h3 className="text-[16px] font-[400] text-[#000000a4]">Discount:</h3>
				<h5 className="text-[18px] font-[600]">
					- {discountPercentage ? "₦" + discountPercentage.toString() : null}
				</h5>
			</div>
			<h5 className="text-[18px] font-[600] text-end pt-3">₦{totalPrice}</h5>
			<br />
			<form onSubmit={handleSubmit}>
				<input
					type="text"
					className={`${styles.input} h-[40px] pl-2`}
					placeholder="Coupon code"
					value={couponCode}
					onChange={(e) => setCouponCode(e.target.value)}
				/>
				<input
					className={`w-full h-[40px] border border-[#f63b60] text-center text-[#f63b60] rounded-[3px] mt-8 cursor-pointer`}
					required
					value="Apply code"
					type="submit"
				/>
			</form>
		</div>
	);
};

export default Checkout;
