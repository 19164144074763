import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import { createevent } from "../../redux/actions/event";

const CreateEvent = () => {
	const { seller } = useSelector((state) => state.seller);
	const { success, error } = useSelector((state) => state.events);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [images, setImages] = useState([]);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [category, setCategory] = useState("");
	const [tags, setTags] = useState("");
	const [originalPrice, setOriginalPrice] = useState("");
	const [discountPrice, setDiscountPrice] = useState("");
	const [stock, setStock] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [location, setLocation] = useState("");
	const [startTime, setStartTime] = useState("");
	const [eventDate, setEventDate] = useState("");
	const [ticketQuantity, setTicketQuantity] = useState("");
	const [loading, setLoading] = useState(false);

	const today = new Date().toISOString().slice(0, 10);

	const handleStartDateChange = (e) => {
		const startDate = new Date(e.target.value);
		const minEndDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
		setStartDate(startDate);
		setEndDate(null);
		document.getElementById("end-date").min = minEndDate.toISOString().slice(0, 10);
	};

	const handleEndDateChange = (e) => {
		const endDate = new Date(e.target.value);
		setEndDate(endDate);
	};

	const handleEventDateChange = (e) => {
		const event_date = new Date(e.target.value);

		setEventDate(event_date);
	};

	const minEndDate = startDate
		? new Date(startDate.getTime() + 24 * 60 * 60 * 1000).toISOString().slice(0, 10)
		: "";

	const minEventDate = endDate ? new Date(endDate).toISOString().slice(0, 10) : "";

	useEffect(() => {
		if (error) {
			toast.error(error);
			setLoading(false);
		}
		if (success) {
			toast.success("Event created successfully!");
			navigate("/dashboard-events");
			window.location.reload();
		}
	}, [dispatch, error, success, navigate]);

	const handleImageChange = (e) => {
		const files = Array.from(e.target.files);

		setImages([]);

		files.forEach((file) => {
			const reader = new FileReader();

			reader.onload = () => {
				if (reader.readyState === 2) {
					setImages((old) => [...old, reader.result]);
				}
			};
			reader.readAsDataURL(file);
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		const newForm = new FormData();

		images.forEach((image) => {
			newForm.append("images", image);
		});

		const data = {
			name,
			description,
			category,
			tags,
			originalPrice,
			discountPrice,
			stock: category === "Tickets" ? ticketQuantity : stock,
			location: category === "Tickets" ? location : null,
			start_Time: category === "Tickets" ? startTime : null,
			images,
			shopId: seller._id,
			start_Date: startDate?.toISOString(),
			Finish_Date: endDate?.toISOString(),
			event_date: eventDate,
		};

		dispatch(createevent(data))
			.then(() => {
				toast.success("Event created successfully.");
				setLoading(false);
				navigate("/dashboard");
			})
			.catch((error) => {
				toast.error(error.response?.data?.message || "Failed to create event"); // Display meaningful error message
				setLoading(false);
			});
	};

	return (
		<div className="w-[90%] 800px:w-[50%] bg-white shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
			{loading && (
				<div className="fixed inset-0 flex items-center justify-center bg-gray-50 bg-opacity-75 z-50">
					<div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-300"></div>
				</div>
			)}
			<h5 className="text-[30px] font-Poppins text-center">Create Event</h5>
			{/* create event form */}
			<form onSubmit={handleSubmit}>
				<br />
				<div>
					<label className="pb-2">
						Name <span className="text-red-500">*</span>
					</label>
					<input
						type="text"
						name="name"
						value={name}
						className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
						onChange={(e) => setName(e.target.value)}
						placeholder="Enter your event product name..."
					/>
				</div>
				<br />
				<div>
					<label className="pb-2">
						Description <span className="text-red-500">*</span>
					</label>
					<textarea
						cols="30"
						required
						rows="8"
						type="text"
						name="description"
						value={description}
						className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
						onChange={(e) => setDescription(e.target.value)}
						placeholder="Enter your event description..."
					></textarea>
				</div>
				<br />
				<div>
					<label className="pb-2">
						Category <span className="text-red-500">*</span>
					</label>
					<select
						className="w-full mt-2 border h-[35px] rounded-[5px]"
						value={category}
						onChange={(e) => setCategory(e.target.value)}
					>
						<option value="Choose a category">Choose a category</option>
						{[...categoriesData, { title: "Tickets" }].map((i) => (
							<option value={i.title} key={i.title}>
								{i.title}
							</option>
						))}
					</select>
				</div>
				<br />

				{category === "Tickets" ? (
					<>
						<div>
							<label className="pb-2">Tags</label>
							<input
								type="text"
								name="tags"
								value={tags}
								className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								onChange={(e) => setTags(e.target.value)}
								placeholder="E.g Tech"
							/>
						</div>
						<br />
						<div>
							<label className="pb-2">Location</label>
							<input
								type="text"
								name="location"
								value={location}
								className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								onChange={(e) => setLocation(e.target.value)}
								placeholder="Enter event location..."
							/>
						</div>
						<br />

						<div>
							<label className="pb-2">Time (24H Format)</label>
							<input
								type="text"
								name="start-time"
								value={startTime}
								className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								onChange={(e) => setStartTime(e.target.value)}
								placeholder="E.g 10AM"
							/>
						</div>
						<br />
						<div>
							<label className="pb-2">Ticket Quantity</label>
							<input
								type="number"
								name="ticket-quantity"
								value={ticketQuantity}
								className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								onChange={(e) => setTicketQuantity(e.target.value)}
								placeholder="Enter ticket quantity..."
							/>
						</div>
						<br />
					</>
				) : (
					<>
						<div>
							<label className="pb-2">Tags</label>
							<input
								type="text"
								name="tags"
								value={tags}
								className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								onChange={(e) => setTags(e.target.value)}
								placeholder="Enter your event product tags..."
							/>
						</div>
						<br />
						<p class="font-bold">
							Note: If there's no discount, enter the regular price in the
							discount price field and leave the original price field blank.
						</p>
						<br />
						<div>
							<label className="pb-2">
								Product Stock <span className="text-red-500">*</span>
							</label>
							<input
								type="number"
								name="price"
								value={stock}
								className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								onChange={(e) => setStock(e.target.value)}
								placeholder="Enter your event product stock..."
							/>
						</div>
						<br />
					</>
				)}
				<div>
					<label className="pb-2">
						{category === "Tickets" ? "Tickets Sales Start" : "Event Start Date"}{" "}
						<span className="text-red-500">*</span>
					</label>
					<input
						type="date"
						name="price"
						id="start-date"
						value={startDate ? startDate.toISOString().slice(0, 10) : ""}
						className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
						onChange={handleStartDateChange}
						min={today}
					/>
				</div>
				<br />
				<div>
					<label className="pb-2">
						{category === "Tickets" ? "Tickets Sales End" : "Event End Date"}{" "}
						<span className="text-red-500">*</span>
					</label>
					<input
						type="date"
						name="price"
						id="end-date"
						value={endDate ? endDate.toISOString().slice(0, 10) : ""}
						className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
						onChange={handleEndDateChange}
						min={minEndDate}
					/>
				</div>
				<br />
				{category === "Tickets" && (
					<>
						<div>
							<label className="pb-2">Event Date</label>
							<input
								type="date"
								name="price"
								id="event-date"
								value={
									eventDate
										? eventDate.toISOString().slice(0, 10)
										: ""
								}
								className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
								onChange={handleEventDateChange}
								min={minEventDate}
							/>
						</div>
					</>
				)}
				<br />
				<p class="font-bold">
					Note: If there's no discount, enter the regular price in the discount price
					field and leave the original price field blank.
				</p>
				<div>
					<label className="pb-2">Original Price</label>
					<input
						type="number"
						name="price"
						value={originalPrice}
						className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
						onChange={(e) => setOriginalPrice(e.target.value)}
						placeholder="Enter your event product price..."
					/>
				</div>
				<br />
				<div>
					<label className="pb-2">
						Price (With Discount) <span className="text-red-500">*</span>
					</label>
					<input
						type="number"
						name="price"
						value={discountPrice}
						className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
						onChange={(e) => setDiscountPrice(e.target.value)}
						placeholder="Enter your event product price with discount..."
					/>
				</div>
				<br />
				<div>
					<label className="pb-2">
						Upload Images <span className="text-red-500">*</span>
					</label>
					<input
						type="file"
						name=""
						id="upload"
						className="hidden"
						multiple
						onChange={handleImageChange}
					/>
					<div className="w-full flex items-center flex-wrap">
						<label htmlFor="upload">
							<AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
						</label>
						{images &&
							images.map((i) => (
								<img
									src={i}
									key={i}
									alt=""
									className="h-[120px] w-[120px] object-cover m-2"
								/>
							))}
					</div>
					<br />
					<div>
						<input
							type="submit"
							value="Create"
							className="mt-2 cursor-pointer inline-flex items-center justify-center w-full px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default CreateEvent;
