import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  LoginPage,
  SignupPage,
  ActivationPage,
  HomePage,
  ProductsPage,
  BestSellingPage,
  EventsPage,
  FAQPage,
  CheckoutPage,
  PaymentPage,
  OrderSuccessPage,
  ProductDetailsPage,
  ProfilePage,
  ShopCreatePage,
  SellerActivationPage,
  ShopLoginPage,
  OrderDetailsPage,
  TrackOrderPage,
  UserInbox,
  NotFoundPage,
  AboutPage,
  ForgotPassword,
  ResetPassword,
  EventDetailsPage,
  FormPage,
  TicketsPage,
  OrderProcessingPage,
  GoogleAuthPage,
} from "./routes/Routes.js";
import {
  ShopDashboardPage,
  ShopCreateProduct,
  ShopAllProducts,
  ShopCreateEvents,
  ShopAllEvents,
  ShopAllCoupouns,
  ShopPreviewPage,
  ShopAllOrders,
  ShopOrderDetails,
  ShopAllRefunds,
  ShopSettingsPage,
  ShopWithDrawMoneyPage,
  ShopInboxPage,
  ShopUpdateProduct,
  CustomShopRedirect,
} from "./routes/ShopRoutes";
import {
  AdminDashboardPage,
  AdminDashboardUsers,
  AdminDashboardSellers,
  AdminDashboardOrders,
  AdminDashboardProducts,
  AdminDashboardEvents,
  AdminDashboardWithdraw,
  PendingVerificationSellers,
} from "./routes/AdminRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/store";
import { loadSeller, loadUser } from "./redux/actions/user";
import ProtectedRoute from "./routes/ProtectedRoute";
import ProtectedAdminRoute from "./routes/ProtectedAdminRoute";
import { ShopHomePage } from "./ShopRoutes.js";
import SellerProtectedRoute from "./routes/SellerProtectedRoute";
import { getAllProducts } from "./redux/actions/product";
import { getAllEvents } from "./redux/actions/event";

const App = () => {
  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(loadSeller());
    Store.dispatch(getAllProducts());
    Store.dispatch(getAllEvents());
  }, []);

  return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/payment"
					element={
						<ProtectedRoute>
							<PaymentPage />
						</ProtectedRoute>
					}
				/>
				<Route path="/" element={<HomePage />} />
				<Route path="/login" element={<LoginPage />} />
				<Route path="/auth/google/callback" element={<GoogleAuthPage />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/reset-password/:token" element={<ResetPassword />} />
				<Route path="/sign-up" element={<SignupPage />} />
				<Route path="/activation/:activation_token" element={<ActivationPage />} />
				<Route path="/seller/activation/:activation_token" element={<SellerActivationPage />} />
				<Route path="/products" element={<ProductsPage />} />
				<Route path="/product/:id" element={<ProductDetailsPage />} />
				<Route path="/best-selling" element={<BestSellingPage />} />
				<Route path="/events" element={<EventsPage />} />
				<Route path="/events/:customName" element={<EventDetailsPage />} />
				<Route path="/events/:shopId/tickets" element={<TicketsPage />} />
				<Route path="/faq" element={<FAQPage />} />
				<Route
					path="/checkout"
					element={
						<ProtectedRoute>
							<CheckoutPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/events/mssnlasuepe/form"
					element={
						<ProtectedRoute>
							<FormPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/order/processing"
					element={
						<ProtectedRoute>
							<OrderProcessingPage />
						</ProtectedRoute>
					}
				/>
				<Route path="/order/success" element={<OrderSuccessPage />} />
				<Route
					path="/profile"
					element={
						<ProtectedRoute>
							<ProfilePage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/inbox"
					element={
						<ProtectedRoute>
							<UserInbox />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/user/order/:id"
					element={
						<ProtectedRoute>
							<OrderDetailsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/user/track/order/:id"
					element={
						<ProtectedRoute>
							<TrackOrderPage />
						</ProtectedRoute>
					}
				/>
				<Route path="/shop/preview/:id" element={<ShopPreviewPage />} />
				<Route path="/shops/:customName" element={<CustomShopRedirect />} />
				{/* shop Routes */}
				<Route path="/shop-create" element={<ShopCreatePage />} />
				<Route path="/shop-login" element={<ShopLoginPage />} />
				<Route path="/shop/reset-password/:token" element={<ResetPassword />} />
				<Route
					path="/shop/:id"
					element={
						<SellerProtectedRoute>
							<ShopHomePage />
						</SellerProtectedRoute>
					}
				/>
				<Route
					path="/settings"
					element={
						<SellerProtectedRoute>
							<ShopSettingsPage />
						</SellerProtectedRoute>
					}
				/>
				<Route
					path="/dashboard"
					element={
						<SellerProtectedRoute>
							<ShopDashboardPage />
						</SellerProtectedRoute>
					}
				/>
				<Route
					path="/dashboard-create-product"
					element={
						<SellerProtectedRoute>
							<ShopCreateProduct />
						</SellerProtectedRoute>
					}
				/>
				<Route
					path="/dashboard-update-product/:id"
					element={
						<SellerProtectedRoute>
							<ShopUpdateProduct />
						</SellerProtectedRoute>
					}
				/>
				<Route
					path="/dashboard-orders"
					element={
						<SellerProtectedRoute>
							<ShopAllOrders />
						</SellerProtectedRoute>
					}
				/>
				<Route
					path="/dashboard-refunds"
					element={
						<SellerProtectedRoute>
							<ShopAllRefunds />
						</SellerProtectedRoute>
					}
				/>

				<Route
					path="/order/:id"
					element={
						<SellerProtectedRoute>
							<ShopOrderDetails />
						</SellerProtectedRoute>
					}
				/>
				<Route
					path="/dashboard-products"
					element={
						<SellerProtectedRoute>
							<ShopAllProducts />
						</SellerProtectedRoute>
					}
				/>
				<Route
					path="/dashboard-create-event"
					element={
						<SellerProtectedRoute>
							<ShopCreateEvents />
						</SellerProtectedRoute>
					}
				/>
				<Route
					path="/dashboard-events"
					element={
						<SellerProtectedRoute>
							<ShopAllEvents />
						</SellerProtectedRoute>
					}
				/>
				<Route
					path="/dashboard-coupouns"
					element={
						<SellerProtectedRoute>
							<ShopAllCoupouns />
						</SellerProtectedRoute>
					}
				/>
				<Route
					path="/dashboard-withdraw-money"
					element={
						<SellerProtectedRoute>
							<ShopWithDrawMoneyPage />
						</SellerProtectedRoute>
					}
				/>
				<Route
					path="/dashboard-messages"
					element={
						<SellerProtectedRoute>
							<ShopInboxPage />
						</SellerProtectedRoute>
					}
				/>
				{/* Admin Routes */}
				<Route
					path="/admin/dashboard"
					element={
						<ProtectedAdminRoute>
							<AdminDashboardPage />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/admin-users"
					element={
						<ProtectedAdminRoute>
							<AdminDashboardUsers />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/admin-sellers"
					element={
						<ProtectedAdminRoute>
							<AdminDashboardSellers />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/admin-orders"
					element={
						<ProtectedAdminRoute>
							<AdminDashboardOrders />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/admin-products"
					element={
						<ProtectedAdminRoute>
							<AdminDashboardProducts />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/admin-events"
					element={
						<ProtectedAdminRoute>
							<AdminDashboardEvents />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/admin-withdraw-request"
					element={
						<ProtectedAdminRoute>
							<AdminDashboardWithdraw />
						</ProtectedAdminRoute>
					}
				/>
				<Route
					path="/admin-new-vendors"
					element={
						<ProtectedAdminRoute>
							<PendingVerificationSellers />
						</ProtectedAdminRoute>
					}
				/>
				<Route path="/about" element={<AboutPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
			<ToastContainer
				position="bottom-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
		</BrowserRouter>
  );
};

export default App;
